import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="col-lg-8 my-5">
      <h2>{`Summary`}</h2>
      <p>{`Esteban was the business specialist for a product concept that unified the diverse technology {chat, NLU, search, big data} being built by the company.   `}</p>
    </div>
    <div className="col-lg-8 my-5 py-5 border-top">
      <h2>{`0. Prior`}</h2>
      <p>{`The OBD (Overseas Business Development) team was tasked with demand generation for the NLU search engine that the company was developing. `}</p>
      <p>{`As a newly created team, documentation, processes and material was not available. `}</p>
      <h4>{`Branding`}</h4>
      <p>{`Esteban co-created marketing material for the team based on the extensive and complex technical ad-hoc specifications sold to local Chaebol companies. `}</p>
      <p>{`To simplify the offering, all projects were categorized under three core families: `}<em parentName="p">{`enterprise`}</em>{`, `}<em parentName="p">{`chatbot`}</em>{` and `}<em parentName="p">{`smart devices`}</em>{`. With these all Korean content was translated to English and simplified as downloadable use cases. `}</p>
      <h4>{`Website`}</h4>
      <p>{`To house all the new content and reduce the introductory PPT, a new website was created. Aligning with the core tech of the company Python was used. The stack included Django and Wagtail CMS implemented over two months. The custom set up was flexible and easy to manage by non tech members.  `}</p>
      <p>{`The changes also improved SEO results with better content, keywords and metrics, doubling daily visits to website within 3 months, multiplied by 10x web presence and, created a tiered funnel using Hubspot.`}</p>
    </div>
    <div className="row my-5">
      <div className="col-md-3 offset-md-2">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/232bfe6fdd723dbfc99d8fc5fcb78724/bcec7/42Maru-02.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMEAQIF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAe/iPWJxNOQSwB//xAAaEAABBQEAAAAAAAAAAAAAAAABAAIQERID/9oACAEBAAEFApc8BDIjpemi4//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAECAQE/ASP/xAAaEAEAAgMBAAAAAAAAAAAAAAABECEAAiIS/9oACAEBAAY/ApS526SjH1fTH//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQXEQUWH/2gAIAQEAAT8hh34QQwDR7hBqzNQwTTcYO5Xua0Xx8hqf/9oADAMBAAIAAwAAABC/CPz/xAAWEQEBAQAAAAAAAAAAAAAAAAARASD/2gAIAQMBAT8QYmP/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEx/9oACAECAQE/EKlRxf/EABsQAQACAwEBAAAAAAAAAAAAAAEAESExQVFx/9oACAEBAAE/EIwvFtvc3EQ7C2mVQjJDV4tPvIQA1FQIKUKO0x3UHysHDxjUFAn/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebrand",
                "title": "Rebrand",
                "src": "/static/232bfe6fdd723dbfc99d8fc5fcb78724/4b190/42Maru-02.jpg",
                "srcSet": ["/static/232bfe6fdd723dbfc99d8fc5fcb78724/e07e9/42Maru-02.jpg 200w", "/static/232bfe6fdd723dbfc99d8fc5fcb78724/066f9/42Maru-02.jpg 400w", "/static/232bfe6fdd723dbfc99d8fc5fcb78724/4b190/42Maru-02.jpg 800w", "/static/232bfe6fdd723dbfc99d8fc5fcb78724/bcec7/42Maru-02.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f20760b8ac39ae7367a62c3aa8bc15fb/bcec7/42Maru-07.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe9GZsCAoP/EABkQAAEFAAAAAAAAAAAAAAAAAAABAhAgMv/aAAgBAQABBQKVH4p//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFRABAQAAAAAAAAAAAAAAAAAAIAH/2gAIAQEABj8CFP8A/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMRGh8P/aAAgBAQABPyFnUKo6EMyKj//aAAwDAQACAAMAAAAQ68g8/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGxAAAgMBAQEAAAAAAAAAAAAAAAERITHBcaH/2gAIAQEAAT8QaG6+Cdqm7GBSH0m2qZ98Fg8PExQxGB//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebrand",
                "title": "Rebrand",
                "src": "/static/f20760b8ac39ae7367a62c3aa8bc15fb/4b190/42Maru-07.jpg",
                "srcSet": ["/static/f20760b8ac39ae7367a62c3aa8bc15fb/e07e9/42Maru-07.jpg 200w", "/static/f20760b8ac39ae7367a62c3aa8bc15fb/066f9/42Maru-07.jpg 400w", "/static/f20760b8ac39ae7367a62c3aa8bc15fb/4b190/42Maru-07.jpg 800w", "/static/f20760b8ac39ae7367a62c3aa8bc15fb/bcec7/42Maru-07.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3579ccf5a45e590b20ccfcaa99840142/bcec7/42Maru-08.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABzejdM9mQLsgLkKf/xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIRIRMj/9oACAEBAAEFAlrh5RU7VcjGJlEltJn/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIQEf/aAAgBAwEBPwE2Q6//xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIQEv/aAAgBAgEBPwFmURr/xAAbEAABBAMAAAAAAAAAAAAAAAAQAAERIQIx8P/aAAgBAQAGPwKnnI9Ao6X/xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMUFhgf/aAAgBAQABPyFYLaOJW66xVkqKVrd8iD6c8MMOeMfasTo5zR/D/9oADAMBAAIAAwAAABAnJ4D/xAAYEQEBAAMAAAAAAAAAAAAAAAABABARQf/aAAgBAwEBPxBODiNov//EABgRAQADAQAAAAAAAAAAAAAAAAEAESEQ/9oACAECAQE/EFZpnFsuf//EABwQAQACAwEBAQAAAAAAAAAAAAEAESExQYFhof/aAAgBAQABPxAXFdgU9WOpZUBi/hDpA91AF6jB8pr2IHZ1diFMhyr9pJmslmR0+TBCmOY56x//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebrand",
                "title": "Rebrand",
                "src": "/static/3579ccf5a45e590b20ccfcaa99840142/4b190/42Maru-08.jpg",
                "srcSet": ["/static/3579ccf5a45e590b20ccfcaa99840142/e07e9/42Maru-08.jpg 200w", "/static/3579ccf5a45e590b20ccfcaa99840142/066f9/42Maru-08.jpg 400w", "/static/3579ccf5a45e590b20ccfcaa99840142/4b190/42Maru-08.jpg 800w", "/static/3579ccf5a45e590b20ccfcaa99840142/bcec7/42Maru-08.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row my-5">
      <div className="col-md-3 offset-md-2">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ecd80d90506c458fedea667e8bc9dafe/bcec7/42Maru-05.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEGBf/EABgBAAIDAAAAAAAAAAAAAAAAAAABAgME/9oADAMBAAIQAxAAAAHus/LFo2ZBy6aIwM//xAAdEAABAgcAAAAAAAAAAAAAAAABAwQCEBESFCBB/9oACAEBAAEFAu1kHaxgLpW7PW0//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAEDAQE/AWd//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAETEP/aAAgBAgEBPwGbJvf/xAAdEAABAgcAAAAAAAAAAAAAAACRAAEDEBIgISJC/9oACAEBAAY/Ap16BZaGFyLP/8QAHBABAQACAgMAAAAAAAAAAAAAAREAIRAxQVFh/9oACAEBAAE/Ib0pfWMIKcKPAzLBQmx2z4ZXUy8f/9oADAMBAAIAAwAAABDDKID/xAAWEQEBAQAAAAAAAAAAAAAAAAARABD/2gAIAQMBAT8QEN//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EAf/xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhMXEQQVHR/9oACAEBAAE/EE2de7bnWCZV9XDZTJDCHJU7uCgEUOXxunI6JGuX7gz4ZYrDrx//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Old website",
                "title": "Old website",
                "src": "/static/ecd80d90506c458fedea667e8bc9dafe/4b190/42Maru-05.jpg",
                "srcSet": ["/static/ecd80d90506c458fedea667e8bc9dafe/e07e9/42Maru-05.jpg 200w", "/static/ecd80d90506c458fedea667e8bc9dafe/066f9/42Maru-05.jpg 400w", "/static/ecd80d90506c458fedea667e8bc9dafe/4b190/42Maru-05.jpg 800w", "/static/ecd80d90506c458fedea667e8bc9dafe/bcec7/42Maru-05.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/94d6ac05ff608d6a9cce53b125a27a7b/bcec7/42Maru-06.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUBAv/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAZXNprExSCuDK9A//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAEQERQSMf/aAAgBAQABBQJuObMJhZguF4f/xAAZEQABBQAAAAAAAAAAAAAAAAABAAIQElH/2gAIAQMBAT8BAVXbP//EABcRAAMBAAAAAAAAAAAAAAAAAAABEhD/2gAIAQIBAT8BpFLf/8QAGxAAAQQDAAAAAAAAAAAAAAAAAAExM6EgQZH/2gAIAQEABj8CY2OSUSUSJzD/xAAdEAADAAICAwAAAAAAAAAAAAAAAREhQRAxYZHB/9oACAEBAAE/IUN/A5oJ0sey+oHiCskFbMuH/9oADAMBAAIAAwAAABDUx/z/xAAYEQACAwAAAAAAAAAAAAAAAAABEQAQ8P/aAAgBAwEBPxBIUwF//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QUiz/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMVGh0f/aAAgBAQABPxC26cwlqVL23HkNKx6YGUtvY/ssBKw7z7NaB4vsVmJhY6FDMACgon//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "New website",
                "title": "New website",
                "src": "/static/94d6ac05ff608d6a9cce53b125a27a7b/4b190/42Maru-06.jpg",
                "srcSet": ["/static/94d6ac05ff608d6a9cce53b125a27a7b/e07e9/42Maru-06.jpg 200w", "/static/94d6ac05ff608d6a9cce53b125a27a7b/066f9/42Maru-06.jpg 400w", "/static/94d6ac05ff608d6a9cce53b125a27a7b/4b190/42Maru-06.jpg 800w", "/static/94d6ac05ff608d6a9cce53b125a27a7b/bcec7/42Maru-06.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/48c61cb3d3ed9aa57d2c144efd62f3b2/bcec7/42Maru-04.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7tQxQm0LAf/EABkQAAIDAQAAAAAAAAAAAAAAAAEQAAIREv/aAAgBAQABBQJVPS2VwBZP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFRABAQAAAAAAAAAAAAAAAAAAICH/2gAIAQEABj8CMH//xAAXEAEBAQEAAAAAAAAAAAAAAAABEQAh/9oACAEBAAE/IS7ugUpGdMqFygusTxbpkp3ADm//2gAMAwEAAgADAAAAEJMQPP/EABgRAAIDAAAAAAAAAAAAAAAAAAARARAh/9oACAEDAQE/ECGtv//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBFB/9oACAECAQE/EB1k/wD/xAAbEAEAAwEAAwAAAAAAAAAAAAABABExIUGBof/aAAgBAQABPxBeD8m9r1FYkmDJmFSwgqr2MRBCtXrKJ2CxhlSFE//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Improved stats",
                "title": "Improved stats",
                "src": "/static/48c61cb3d3ed9aa57d2c144efd62f3b2/4b190/42Maru-04.jpg",
                "srcSet": ["/static/48c61cb3d3ed9aa57d2c144efd62f3b2/e07e9/42Maru-04.jpg 200w", "/static/48c61cb3d3ed9aa57d2c144efd62f3b2/066f9/42Maru-04.jpg 400w", "/static/48c61cb3d3ed9aa57d2c144efd62f3b2/4b190/42Maru-04.jpg 800w", "/static/48c61cb3d3ed9aa57d2c144efd62f3b2/bcec7/42Maru-04.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="col-md-6 mt-3">
      <h2>{`01. Research`}</h2>
      <p>{`An experienced R&D expert (ex-Samsung and ex-Akamai) led the technological side and Esteban the busines side analysing various industries and the technology available overseas. `}</p>
      <h2>{`02. Product Design`}</h2>
      <p>{` Over a couple of months a various product concepts were created. The one with most potential was an assistant could be applied to companies with large customer bases. To serve as single source of truth via AI chatbots for support staff to offer personalized services. `}</p>
      <p>{`The product concept included: Market opportunities, competitors, development roadmap, screen designs and database structure.`}</p>
    </div>
    <div className="row my-5">
      <div className="col-md-3 offset-md-2">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b46e594fe0562dbc3b49adeeaa4071c9/df56e/42maru_1.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACaklEQVQ4y7WUy0sVURzH3bWpVX9ARBJC0AN7GGQLibLHSqhFkEWG0MNUMjXKpE2FFG56GBFKUkRFRUUREi2MSiMIU8zx3usj586cO487c+bcmTnvuHNb3GKo6PHjLM+H3/f3/f7OKZF/USX/DBa/AVgWgjCIgQkTFvSDIPwJDCGGEH8HC5HvOjYx3XX5bnfPPYIpDknhUMxwSAI/D3xWZnpvP307PBIDp4Hx6PmrZwODhFAEfejmcl4wowHDdhD0w4Aok9OXrt0aej8SIUWyORdBiJGXgy5CyOecFyx4o6RmbbdwW0qJUC4MyY8zM8Y+jSpAt9OqMT6e6ut/ubv2wt667o6uh21n7uzZd7G2rntoWIna8PioCvo541u2dy5YuGvx0gObt3VsrGpbtGT/vPk1x9v78r4SFgNzIUQkNgxJzc6zy8sbVq1trKhsWbehpbyiubSs/uTpfiklpXGwIEREkjjnW3d0rl7fvGzl4U3Vpyqr2lesaSwtq2890RcPk8D3tSnJKKNUSnn/weuDDVeONPUca73R1HK9oann0NGrH0dSP85c8HU0AV6cq5bmOxUEhuHaNtL0LGcC6FmMaRjSXC5MJDXLQsmkHmLyDY4i4B96nwzePC9ZQssQ00Cq6iqKblnB5CRwHKwDT1Xd0bE5CGkiATCmRUvCOHs8IMYmpJRzX9REatpDXjbrpNN6amrW8zwAQMawNE13HMfOOkUzR/EwM0uVZMCYmbFNw84AS0tngG6qc/rsjEYpxRgTQjDGcTljko+IMoIpY9x1EQBWBtiaZkKYK1pEXtiFP3nPIqpfw0L8z5/kK3HeRA34hBVuAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Product concept",
                "title": "Product concept",
                "src": "/static/b46e594fe0562dbc3b49adeeaa4071c9/5a190/42maru_1.png",
                "srcSet": ["/static/b46e594fe0562dbc3b49adeeaa4071c9/772e8/42maru_1.png 200w", "/static/b46e594fe0562dbc3b49adeeaa4071c9/e17e5/42maru_1.png 400w", "/static/b46e594fe0562dbc3b49adeeaa4071c9/5a190/42maru_1.png 800w", "/static/b46e594fe0562dbc3b49adeeaa4071c9/df56e/42maru_1.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3 ">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/16b2d23018ad333e1b24cf0945c48007/bcec7/42Maru-09.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHdIEshQAP/xAAbEAAABwEAAAAAAAAAAAAAAAABAgMQEiAhQf/aAAgBAQABBQLX6maQ0//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABgQAAIDAAAAAAAAAAAAAAAAAAABEBEg/9oACAEBAAY/Apoef//EABoQAAIDAQEAAAAAAAAAAAAAAAABEBExIVH/2gAIAQEAAT8hb6wtiG74GAfosilH/9oADAMBAAIAAwAAABDcCDz/xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAwEBPxBj/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQIBAT8QI//EABwQAQEAAgMBAQAAAAAAAAAAAAERADEhQVEQYf/aAAgBAQABPxATBBrn8z3DGpzN9ZVoIW+5Nx7VWN1ioZKaeshbOcAsJd/P/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Product concept",
                "title": "Product concept",
                "src": "/static/16b2d23018ad333e1b24cf0945c48007/4b190/42Maru-09.jpg",
                "srcSet": ["/static/16b2d23018ad333e1b24cf0945c48007/e07e9/42Maru-09.jpg 200w", "/static/16b2d23018ad333e1b24cf0945c48007/066f9/42Maru-09.jpg 400w", "/static/16b2d23018ad333e1b24cf0945c48007/4b190/42Maru-09.jpg 800w", "/static/16b2d23018ad333e1b24cf0945c48007/bcec7/42Maru-09.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row my-5">
      <div className="col-md-3 offset-md-2">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ebb9655838ad0ea0964796f059d70b06/df56e/42maru_4.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD2UlEQVQ4y6WU22/adhzF+Qe2l926qum6Rs1lARJzN2AuBgzY2FwChASSwEoJARIMBGwHiDF3nJA0l3bNlMs0tdESoiabtj3sZdKequ2P2gREk7bX/nRePzrn/I70Ffz9Hk/w869/tbZ7ewc/7u7ftvjrZqfX7PQa7aut2sVQZe4NU/yeKfWVp88zGycb1NlK4sXtT+8EjfaVzsRgzqrVUYFgWm2gtAYK1BWmFeS0gpxRkkLp+pOplTFhfFwUf/Tk6YMvw2p94cOP59jqhWB3/wZzVueCvC/QRp2cDa/YcQ5xsJBlEzIzOsumxkjLwKxcnVVocmLpuhBI6WD64ejXre1rQXfvxo5XfPNtj79lwysWdMuKsWZ0SwPTQ4H6glSZkYFZqSojkqyNC+OT4sSnDxb78M7uW4erGgp3Pf6m1VHBPXVitmGylSEzg+AVvaWo0uVlYAaCaRmYEQIpIZBS6wv3RpZa/AAOLXdzhVN/kEcw1uVrurxN2Foy2ctPVw5Rd00BbcjA7ADOCoEkIF/Xm5nPHy61+N4gNlHxzrdn/U07zpntZQu6dRcbYbRmBtQXJEoSUJB9WJJ+NJH8ajrx2chi33nYeW6e9yx0rIPOfWGsSptfpF5Z/XWFOidVZSRKUiTLgPJIyDY7JkoOnK8FO3s3KMERfhYK0pZAHSOqTm/DiBTNGHvyy59M+1qiJOXqnA6mZRrqYOfbd5esWl/45H6ovT2AMWfV7tpEI1QgySNYhZitm2wlg61E13sx8kSuycrBrKY/fv6b099i5KlYstaPPZzK5mAXw7t5+ju3v9X3tG8ZrSW7kwssbbvnmlqYnpalZ+RpsXRdJEmJgeSEaPXeMPawcyDEh6PPvYEO7qk7vQ3cU7dgrHehQ/gasK2kNVJamIKM9Iw8PSlOCIHU3VR3vx1oE566x9/CXFXMVZsLbZvQsh4pGqwlG8GptHkQyutgGlCQY1Px/8Aowbl9zRx1Gl09MiJFBGMd7poeKar0eYO1GIx05ercFJAKRXZ1JmZ0IvZ/2OVt1FuXjU7PiBRRZzUS3fcudJS6vN3FraReag2UWLre4N8iGPt4PCqS/As/v0UJzr/Ax5Mvg8tdC8paHZXo6hE+Wwf1BcxdsxOcwcKooA0YKcrAzKR4VSRZu//Fcn+qFt8zWjZxdxX31Ey2shamIROjhRmpOicBswCYmVakJ8Srk+LE1ExydDI28jgyOhH74CM/1/hBcHn1R545Z6tvypXXG/R5jjrLUWfZwmmSPE6Sx6nMcSL96ln88Fn8MJY4CkefB8PdaPzA5Wu8vvhd8D5n6B8ymRH+F4gDjwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Product concept",
                "title": "Product concept",
                "src": "/static/ebb9655838ad0ea0964796f059d70b06/5a190/42maru_4.png",
                "srcSet": ["/static/ebb9655838ad0ea0964796f059d70b06/772e8/42maru_4.png 200w", "/static/ebb9655838ad0ea0964796f059d70b06/e17e5/42maru_4.png 400w", "/static/ebb9655838ad0ea0964796f059d70b06/5a190/42maru_4.png 800w", "/static/ebb9655838ad0ea0964796f059d70b06/df56e/42maru_4.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3 ">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/00ead5da3fdc2b91c2f0881043b9ca9f/df56e/42maru_2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADW0lEQVQ4y52U22/TVhzH+0cUaaQUcilxrjaDscLD4IEyWkBM2qbBnprQpk1c0Q21a4daXjaGmKh4YBKDTe0Yi0gDCHERCBVEJDIJaao2LmItrKRtrm58i33s49i14yl1SLqChLSfPjrH+slf/76/c3xOncMbMnCiuBPFXcaIrcRIOlG8+r7DG6ozJsQTXNfUsdbW0Wg92Gg7uMbir2H1N1r9a8tJnwUJIJ6g3R2sie3u4Lvv93Z2jXTjPxw6fO7Qlz/2DYz2D1boGxg93P9zT++Zga/HPtt/zIuFNjV/YejrnChuRbp27T5yJ/707M3pR4mCqqolXTeoBkWSoqKMXp3c3Hps644hu6vLgeKGONDSOjT9YjaZzrOcSJIMx/EilCAsilCSZUXTSrksMZOhT47/6d17yr3lK4e7u1o5sHP3cHIuydAMFCHLspIklUrlwqVK6KlULkMs/HrpdxvWizb3OTw124GWtqGFPAWAIAgSSbE0TUMIDf3SJ/RsJvsyxx4ff/Le5+c2fvgt4goss902RCyQHAeKRZkkyWw2CwBQFKXac2J2nmPz167E6us/dbrLDf+nMkGQAAgAQIYpFItFSZIghFX/yVR2Nk38NP5wffOgd0v/StsEQfK8IAiQohie58pPEIqiuNSynk6m/p4jvo8+2bz/LNbyzWu2K5VFimIghNpSqKq6uLioaVoqnVMk4fqV2Kr6T1yelbaHcwTJFoAsL/71+PnU8xme5wAQRFGUZVnVtPm55FQiczo6ibWdQLcNI+5l+7yz9QhH5VVJkCVpPpmhaUZRlOpqa1op8TLxIrkwcnES23MS3X60JrYhndv3HY9N0fF/uLm8WJQkVVU1rbpPmgCEG7fiFElcjt5/Z/UBlzfoQEMV2032jm0fj0Qm6Qt/0M/SvMgVChwPgAChJIqQphkiR9yaiGVzxPnf7jXaOtwbeioHw4nhVntny0ffTefhDKtyRVWWoaYpuq6++rvLIw+AruvXbjw0rWtfJkZDTa7urR/0jY3dDofvXgjf/yX8IBKNRy/Hx5eIRB+EI7Gx8xMXI7H+wVErEqiJjcnuCTZY/Caz32TxrbH4TOZ2k7m9oYKvwVxONph95vWdLgxfeRk4vCH3hp634sJwxBN6g/h/8C8XDZsJHPgCVQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Product Mockup",
                "title": "Product Mockup",
                "src": "/static/00ead5da3fdc2b91c2f0881043b9ca9f/5a190/42maru_2.png",
                "srcSet": ["/static/00ead5da3fdc2b91c2f0881043b9ca9f/772e8/42maru_2.png 200w", "/static/00ead5da3fdc2b91c2f0881043b9ca9f/e17e5/42maru_2.png 400w", "/static/00ead5da3fdc2b91c2f0881043b9ca9f/5a190/42maru_2.png 800w", "/static/00ead5da3fdc2b91c2f0881043b9ca9f/df56e/42maru_2.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/64d3c1f32509ccda1aac555a4269b808/df56e/42maru_3.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADpUlEQVQ4y51U32/bVBTOv4Fo07RJm9hxbKfJoJ1E0UAIIRAwNGDwsDa/asdO05ZREBIvW+lQpaGWtIMXhIBJe0AUtLVJR7I9bGmbZFLZpo12v7KtTRMnTuo6sZM4iZuLnHSUh4mHSZ/uvQ/nu+fc833nqgwm9zND1dz0iFuPkE+N0COkHiG7jHtohnXCxB4ZQt1otwfBPUaMhjEKxqh9MkKiZhqzeHDrMGYdxixeBPfAGNXbNwZjlKq9y3nk6KkL/lW//8rS1dhKdP3GjQdHPpxo0zlgjIYs3nNzkfC1+/P+Zb8/vLR0fdo3pzW4XnrlcxijVWqtfZDyMaxYLosVqVitVgAATnL6ec0AbB7qPXpmbjUbihfXN9kCvw0ACFyM6gwuGFWqU8hO8lueL6RSDMOkczklot/xTUuHTclsIm7efLC58Tgej+fzBQBAMLT69nvjb707bsRplVpnd7l9ZamWy3HJZIplc9Wq1O843dJugzEKMhErkXVBFLJsjuN25Fo1cDH22htfvvnOCVO3R9Wq6R8kpsqisJ1ld/h8ni8AUCfpGfyAt7fvMwglb91+VJNlhskkk4woFn7/46paZ9+TSmsedRz/6U5KvJ9gOY7L54VKpez2zGBWr7V31AA7/74V57IMm8kIgggAWAjEWjsG9EiDDB066RxfCK3zsbtpoZDn+cLurkxQPiNOYwdGkINjgfC9h6y4kWQz6UytVvUHouYXvAdfHlPe3KYnbNSZRJpPMCzH7eRy21K55B6a1SMkYhnuPjz1a2RrcY27fm8rmUjIcjWwGLP0jPS9+gViplXw65OuydDC3dL8YpRn2W0uXyqJ1NCspWcUt3oNCLG8ssZxHMOkm92eX4g0ylZ8pmrR9Lup6Xq9nnjMSOWqVKkBANxDs90vjph7jls+/uHH5fTZVS52J8ll2TrYPX9hqb3LbsSf6Oxy+3heyLDZJzrXj9lPP6c+BqMUZBoM/7nCZTMbm5tbW6lGw6JtOsUCe+RBaqZYkkolxV6yvAsAcBBTrR0DRpyGUDJ4/kqtUitL1WJRMd+8P7JP1nQ6P/ho4tLliD8QDl2KBoOx4OW/Dr9/sq3ToZgEJW+vPZRlSRQFSSo3pWrvskNNezbkJmGMMGLKqhxwAjL9O57kJ59+N/H12RNf/Tw+8cupyXOz3/+GWTyKc1GqOZKUEacb8DTRvLgJTadDrbWrdXa11qbW2mCUhFDqv5n/D7Ay5Pt4yk/ybN/QPwZmUzplpQ45AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Product concept",
                "title": "Product concept",
                "src": "/static/64d3c1f32509ccda1aac555a4269b808/5a190/42maru_3.png",
                "srcSet": ["/static/64d3c1f32509ccda1aac555a4269b808/772e8/42maru_3.png 200w", "/static/64d3c1f32509ccda1aac555a4269b808/e17e5/42maru_3.png 400w", "/static/64d3c1f32509ccda1aac555a4269b808/5a190/42maru_3.png 800w", "/static/64d3c1f32509ccda1aac555a4269b808/df56e/42maru_3.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="col-lg-8 my-5 py-5 border-top">
      <h2>{`Representative`}</h2>
      <p>{`As a member of the OBD team, Esteban participated in online and physical events, including an appearance in Arirang TV. `}</p>
    </div>
    <div className="row my-5">
      <div className="col-md-3 offset-md-2">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/61661404696af919fbdaacc84536e0d7/bcec7/42maru_1.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAMCBAX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGk8JuOeFW1MCAWv//EABsQAAMAAwEBAAAAAAAAAAAAAAECAwAEEhEz/9oACAEBAAEFAjCWLCebAC2p2MBfrY+r7DlRenj0Jb//xAAYEQACAwAAAAAAAAAAAAAAAAAAARAhQf/aAAgBAwEBPwHBQnR//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAERITFBAjJRcZH/2gAIAQEABj8C2Ic6UxpWMwZ8GcdF5qTQ/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFRkf/aAAgBAQABPyEhZEgPAahg1jJaKV6C5Wjl7xUCEt8mMrXbQ29h2M0fE//aAAwDAQACAAMAAAAQrzBD/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQARITH/2gAIAQMBAT8QJ4IDJsdv/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQAxESH/2gAIAQIBAT8Q57Oys7f/xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMUFhUYH/2gAIAQEAAT8Qc526E7KRc+4rpfIO8igMaICTplmXyooBY0IxcYBl+EOiei0F83HNiyGqBzemX4bX3z2f/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Arirang TV",
                "title": "Arirang TV",
                "src": "/static/61661404696af919fbdaacc84536e0d7/4b190/42maru_1.jpg",
                "srcSet": ["/static/61661404696af919fbdaacc84536e0d7/e07e9/42maru_1.jpg 200w", "/static/61661404696af919fbdaacc84536e0d7/066f9/42maru_1.jpg 400w", "/static/61661404696af919fbdaacc84536e0d7/4b190/42maru_1.jpg 800w", "/static/61661404696af919fbdaacc84536e0d7/bcec7/42maru_1.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3 ">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5a7d0e975e437cf6f0afbe405f1883be/bcec7/42maru_2.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQD/8QAFwEBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABxzpyZmtFMxzuIVP/xAAdEAABBAIDAAAAAAAAAAAAAAACAAEDBBESISIy/9oACAEBAAEFAnIhXod3UjEUM7aRvgVjiwrHaf8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREg/9oACAEDAQE/AUuP/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQIBAT8BuNEf/8QAHxAAAgEDBQEAAAAAAAAAAAAAAAERAiFhAxIxQoGR/9oACAEBAAY/Au3rKsstKLxuTKIXKIcfRmlhlcn/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMVFxYaH/2gAIAQEAAT8hVLxxC1Fux3BuAdXA3Aqvcgiodq7mFBhzSIR8YMXh+Q/EaJ//2gAMAwEAAgADAAAAECA3v//EABgRAQADAQAAAAAAAAAAAAAAAAABETFB/9oACAEDAQE/ENUqpxOv/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQITH/2gAIAQIBAT8QCOoBMOC//8QAHRABAQACAwADAAAAAAAAAAAAAREAITFBcWGBkf/aAAgBAQABPxBh4BtoatdbwUSgbo0eYUxjIGJHUxVCR6vmWJtZlg4Pm4Lz2gLgOHzFHYJexw+RW/CMBG31wBn/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Next Rise 2020",
                "title": "Next Rise 2020",
                "src": "/static/5a7d0e975e437cf6f0afbe405f1883be/4b190/42maru_2.jpg",
                "srcSet": ["/static/5a7d0e975e437cf6f0afbe405f1883be/e07e9/42maru_2.jpg 200w", "/static/5a7d0e975e437cf6f0afbe405f1883be/066f9/42maru_2.jpg 400w", "/static/5a7d0e975e437cf6f0afbe405f1883be/4b190/42maru_2.jpg 800w", "/static/5a7d0e975e437cf6f0afbe405f1883be/bcec7/42maru_2.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      